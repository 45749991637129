// Libraries
import React from 'react';

// Supermove
import {Icon, Link, Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {ResponsiveType, useResponsive, useToast} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import Button from '@shared/design/components/Button';
import IconButton from '@shared/design/components/IconButton';
import Switch from '@shared/design/components/Switch';
import Table from '@shared/design/components/Table';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';

const HeaderContainer = Styled.View<{
  isResponsive?: boolean;
  responsive: ResponsiveType;
}>`
  padding-horizontal: ${({isResponsive, responsive}) => (isResponsive && !responsive.desktop ? 16 : 0)}px;
  flex-direction: row;
`;

const HeaderLeft = Styled.View`
  flex: 4;
`;

const HeaderRight = Styled.View`
  flex: 1;
  align-items: flex-end;
`;

const HeaderTitle = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const HeaderDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
  max-width: 700px;
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const OrganizationSettingsCompanyLeadProvidersPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsCompanyLeadProvidersPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage
      title={'Lead Providers'}
      description={'Create and manage your lead providers.'}
      HeaderComponent={Header}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <Content
            viewer={data?.viewer}
            endpoints={data.viewer.viewingOrganization.leadProviderEndpoints}
            refetch={refetch}
          />
        )}
      </Loading>
    </CompanySettingsPage>
  );
};

const Header = () => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <HeaderContainer isResponsive responsive={responsive}>
        <HeaderLeft>
          <Space height={32} />
          <HeaderTitle responsive={responsive}>{'Lead Providers'}</HeaderTitle>
          <Space height={12} />
          <HeaderDescription responsive={responsive}>
            {'Create and manage your lead providers.'}
          </HeaderDescription>
          <Space height={24} />
        </HeaderLeft>
        <HeaderRight>
          <Space height={32} />
          <Button
            onPress={console.log}
            iconLeft={Icon.Plus}
            text={'Add Lead Provider'}
            style={{minWidth: 120}}
          />
        </HeaderRight>
      </HeaderContainer>
    </React.Fragment>
  );
};

const Content = ({
  viewer,
  endpoints,
  refetch,
}: {
  viewer: UserModel;
  endpoints: LeadProviderEndpoint[];
  refetch: () => void;
}) => {
  const copiedToast = useToast({
    ToastComponent: SuccessToast,
    message: `Copied to clipboard.`,
  });

  const {viewingOrganization, role} = viewer;
  const columnDefinitions = [
    {
      headerLabel: ' ',
      flex: 0.5,
      cellComponent: ({inboundWebhookEndpoint}: LeadProviderEndpoint) => {
        return (
          <Switch
            isOn={inboundWebhookEndpoint.status === 'active'}
            onChange={() => console.log('TODO')}
          />
        );
      },
    },
    {
      headerLabel: 'Status',
      flex: 1,
      cellComponent: ({inboundWebhookEndpoint}: LeadProviderEndpoint) => (
        <Badge
          isResponsive
          label={
            inboundWebhookEndpoint.status[0].toUpperCase() +
            inboundWebhookEndpoint.status.substring(1)
          }
          color={
            inboundWebhookEndpoint.status === 'active' ? colors.green.status : colors.gray.secondary
          }
          style={{marginVertical: 2}}
        />
      ),
    },
    {
      headerLabel: 'Name',
      flex: 3,
      cellComponent: ({inboundWebhookEndpoint}: LeadProviderEndpoint) => (
        <Link to={`/settings/company/lead-providers/${inboundWebhookEndpoint.uuid}`}>
          <LinkText>{inboundWebhookEndpoint.name}</LinkText>
        </Link>
      ),
      secondary: {
        headerLabel: 'Description',
        cellText: ({inboundWebhookEndpoint}: LeadProviderEndpoint) => {
          return inboundWebhookEndpoint.description;
        },
      },
    },
    {
      headerLabel: 'Type',
      flex: 1,
      cellText: (endpoint: LeadProviderEndpoint) => endpoint.type,
    },
    {
      headerLabel: 'Webhook ID',
      flex: 3,
      cellText: ({inboundWebhookEndpoint}: LeadProviderEndpoint) => inboundWebhookEndpoint.uuid,
    },
    {
      headerLabel: ' ',
      flex: 0.5,
      cellComponent: ({inboundWebhookEndpoint}: LeadProviderEndpoint) => (
        <IconButton
          onPress={() => {
            navigator.clipboard.writeText(inboundWebhookEndpoint.url);
            copiedToast.handleToast();
          }}
          source={Icon.Copy}
        />
      ),
    },
    {
      headerLabel: 'Last Request',
      flex: 2,
      cellText: ({inboundWebhookEndpoint}: LeadProviderEndpoint) =>
        Datetime.toDisplayTime(
          Datetime.fromDatetime(inboundWebhookEndpoint.summary.latestRequestAt),
          Datetime.DISPLAY_DATETIME,
          viewingOrganization.timezone,
        ),
    },
    {
      headerLabel: 'Leads',
      flex: 1,
      cellText: ({inboundWebhookEndpoint}: LeadProviderEndpoint) =>
        inboundWebhookEndpoint.summary.requests,
    },
    {
      headerLabel: 'Created At',
      flex: 2,
      cellText: ({inboundWebhookEndpoint}: LeadProviderEndpoint) =>
        Datetime.toDisplayTime(
          Datetime.fromDatetime(inboundWebhookEndpoint.createdAt),
          Datetime.DISPLAY_DATETIME,
          viewingOrganization.timezone,
        ),
      secondary: {
        cellText: ({inboundWebhookEndpoint}: LeadProviderEndpoint) =>
          inboundWebhookEndpoint.createdByUser.fullName,
      },
    },
  ];

  return (
    <Table
      items={endpoints}
      itemKey={'id'}
      columnDefinitions={columnDefinitions}
      hasBorder
      emptyStateText={'No lead providers added yet.'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyLeadProvidersPage.query = gql`
    query OrganizationSettingsCompanyLeadProvidersPage {
        ${gql.query}
        viewer {
            id
            viewingOrganization {
                id
                timezone
                leadProviderEndpoints {
                    id
                    type
                    inboundWebhookEndpoint {
                        id
                        uuid
                        createdAt
                        name
                        description
                        status
                        url
                        summary {
                            requests
                            latestRequestAt
                        }
                        createdByUser {
                          id
                          fullName 
                        }
                    } 
                }
            }
        }
    }
`;

interface LeadProviderEndpoint {
  id: string;
  type: string;
  inboundWebhookEndpoint: {
    createdAt: string;
    uuid: string;
    name: string;
    description: string;
    status: 'active' | 'inactive';
    url: string;
    summary: {
      requests: number;
      latestRequestAt: string;
    };
    createdByUser: {
      id: string;
      fullName: string;
    };
  };
}

export default OrganizationSettingsCompanyLeadProvidersPage;
