// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  MutationError,
  ResponsiveType,
  useQuery,
  useResponsive,
  useState,
  useToast,
} from '@supermove/hooks';
import {DocumentTemplateModel, ProjectModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Checkbox from '@shared/design/components/Checkbox';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SearchBar from '@shared/design/components/SearchBar';
import SkeletonLoader from '@shared/design/components/SkeletonLoader';
import Table from '@shared/design/components/TableV2Deprecated';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import GenerateCustomDocumentsForJobForm from '@shared/modules/Job/forms/GenerateCustomDocumentsForJobForm';
import useGenerateDocumentsForJobMutation from '@shared/modules/Job/hooks/useGenerateCustomDocumentsForJobMutation';
import DocumentTemplatePreview from 'modules/Job/V2/Move/components/DocumentTemplatePreview';

const TableHeaderText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const TableBodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const Touchable = Styled.Touchable`
`;

const IconContainer = Styled.View`
  align-items: center;
  justify-content: center;
  width: 40px;
`;

type SetPreviewedDocumentTemplateType = (documentTemplate?: DocumentTemplateModel) => void;

const handlePreviewDocumentTemplate = ({
  previewedDocumentTemplate,
  setPreviewedDocumentTemplate,
  documentTemplateToPreview,
}: {
  previewedDocumentTemplate?: DocumentTemplateModel;
  setPreviewedDocumentTemplate: SetPreviewedDocumentTemplateType;
  documentTemplateToPreview: DocumentTemplateModel;
}) => {
  if (previewedDocumentTemplate) {
    if (previewedDocumentTemplate.id === documentTemplateToPreview.id) {
      setPreviewedDocumentTemplate(undefined);
      return;
    }
  }
  setPreviewedDocumentTemplate(documentTemplateToPreview);
};

const handleDocumentTemplateToggle = ({documentTemplate, form}: any) => {
  const currentIdentifiers = _.get(
    form.values,
    'generateCustomDocumentsForJobForm.documentTemplateIdentifiers',
  );
  const updatedIdentifiers = _.xor(currentIdentifiers, [documentTemplate.identifier]);
  form.setFieldValue(
    'generateCustomDocumentsForJobForm.documentTemplateIdentifiers',
    updatedIdentifiers,
  );
};

const getColumnDefinitions = ({
  documentTemplates,
  previewedDocumentTemplate,
  setPreviewedDocumentTemplate,
  form,
  responsive,
}: {
  documentTemplates: DocumentTemplateModel[];
  previewedDocumentTemplate?: DocumentTemplateModel;
  setPreviewedDocumentTemplate: SetPreviewedDocumentTemplateType;
  form: any;
  responsive: ResponsiveType;
}) => [
  {
    width: 40,
    headerContent: () => {
      const field = 'generateCustomDocumentsForJobForm.documentTemplateIdentifiers';
      const allDocumentTemplateIdentifiers = documentTemplates.map(
        (documentTemplate: any) => documentTemplate.identifier,
      );
      const selectedDocumentTemplateIdentifiers = _.get(form.values, field);
      const isAllSelected = _.isEqual(
        selectedDocumentTemplateIdentifiers,
        allDocumentTemplateIdentifiers,
      );
      return (
        <Checkbox
          isChecked={isAllSelected}
          handleToggle={() => {
            if (isAllSelected) {
              form.setFieldValue(field, []);
            } else {
              form.setFieldValue(field, allDocumentTemplateIdentifiers);
            }
          }}
        />
      );
    },
    // @ts-expect-error TS(7031): Binding element 'documentTemplate' implicitly has ... Remove this comment to see the full error message
    cellContent: ({item: documentTemplate}) => {
      return (
        <Checkbox
          isChecked={_.includes(
            _.get(form.values, 'generateCustomDocumentsForJobForm.documentTemplateIdentifiers'),
            documentTemplate.identifier,
          )}
          handleToggle={() => handleDocumentTemplateToggle({documentTemplate, form})}
        />
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText responsive={responsive}>Document Template Name</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'document' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: document}) => {
      return <TableBodyText responsive={responsive}>{document.name}</TableBodyText>;
    },
  },
  {
    headerContent: () => {
      return <React.Fragment />;
    },
    // @ts-expect-error TS(7031): Binding element 'documentTemplate' implicitly has ... Remove this comment to see the full error message
    cellContent: ({item: documentTemplate}) => {
      return (
        <Touchable
          onPress={() => {
            handlePreviewDocumentTemplate({
              previewedDocumentTemplate,
              setPreviewedDocumentTemplate,
              documentTemplateToPreview: documentTemplate,
            });
          }}
        >
          <IconContainer>
            <Icon
              color={colors.blue.interactive}
              size={Icon.Sizes.Large}
              source={Icon.SearchPlus}
            />
          </IconContainer>
        </Touchable>
      );
    },
  },
];

const PageLoader = () => {
  return (
    <React.Fragment>
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} isFullWidth />
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} isFullWidth />
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} isFullWidth />
    </React.Fragment>
  );
};

const DocumentTemplateViewerContent = ({
  project,
  documentTemplates,
  previewedDocumentTemplate,
  setPreviewedDocumentTemplate,
  form,
}: {
  project: ProjectModel;
  documentTemplates: DocumentTemplateModel[];
  previewedDocumentTemplate?: DocumentTemplateModel;
  setPreviewedDocumentTemplate: SetPreviewedDocumentTemplateType;
  form: any;
}) => {
  const responsive = useResponsive();
  const [searchTerm, setSearchTerm] = useState('');
  documentTemplates = _.sortBy(
    _.filter(
      documentTemplates,
      (documentTemplate) =>
        documentTemplate.activeOrMostRecentDocumentTemplateVersion &&
        documentTemplate.name.toLowerCase().includes(searchTerm.toLowerCase()),
    ),
    ['name'],
  );
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Job'}
        index={0}
        name={'generateCustomDocumentsForJobForm.jobId'}
        component={DropdownInput}
        isRequired
        isResponsive
        input={{
          isSingleOptionSelected: true,
          isPortaled: true,
          options: _.map(project.activeJobs, (job) => ({
            label: `${job.jobType.name} ${job.identifier}`,
            value: job.id,
            jobUuid: job.uuid,
          })),
          style: {flex: 1},
          setFieldValue: () => {},
          onChangeValue: (jobId: any, {jobUuid}: any) => {
            form.setFieldValue('generateCustomDocumentsForJobForm.jobId', jobId);
            form.setFieldValue('generateCustomDocumentsForJobForm.jobUuid', jobUuid);
          },
        }}
      />
      <Space height={16} />
      {_.get(form.values, 'generateCustomDocumentsForJobForm.jobId') && (
        <React.Fragment>
          <SearchBar
            placeholder={'Search by document template...'}
            onChangeText={setSearchTerm}
            style={{flex: 1}}
            isResponsive
          />
          <Space height={16} />
          <Table
            columnDefinitions={getColumnDefinitions({
              documentTemplates,
              previewedDocumentTemplate,
              setPreviewedDocumentTemplate,
              form,
              responsive,
            })}
            emptyStateText={'No document templates'}
            items={documentTemplates}
            itemKey={'id'}
            isClickable
            onRowPress={(item) => handleDocumentTemplateToggle({documentTemplate: item, form})}
          />
        </React.Fragment>
      )}
      <Space height={16} />
    </React.Fragment>
  );
};

const DocumentTemplateDrawer = ({
  loading,
  project,
  previewedDocumentTemplate,
  setPreviewedDocumentTemplate,
  isOpen,
  handleClose,
  documentTemplateIdentifierCount,
  form,
  submitting,
  handleSubmit,
}: {
  loading: boolean;
  project: ProjectModel;
  previewedDocumentTemplate?: DocumentTemplateModel;
  setPreviewedDocumentTemplate: SetPreviewedDocumentTemplateType;
  isOpen: boolean;
  handleClose: () => void;
  documentTemplateIdentifierCount: number;
  form: any;
  submitting: boolean;
  handleSubmit: () => void;
}) => {
  const responsive = useResponsive();

  return (
    <DrawerWithAction
      isOpen={isOpen}
      headerText={'Document Library'}
      handleClose={handleClose}
      width={448}
      isDisabled={_.isEmpty(
        _.get(form.values, 'generateCustomDocumentsForJobForm.documentTemplateIdentifiers'),
      )}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      secondaryAction={handleClose}
      primaryActionText={`Generate (${documentTemplateIdentifierCount})`}
      secondaryActionText={'Cancel'}
      popoutPanel={{
        isOpen: !!previewedDocumentTemplate,
        component: (
          <DrawerWithAction.PopoutPanel
            title={
              responsive.desktop
                ? `Preview: ${previewedDocumentTemplate?.name}`
                : 'Document Preview'
            }
            handleClose={() => setPreviewedDocumentTemplate(undefined)}
          >
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                width: responsive.desktop ? 864 : 'auto',
                backgroundColor: responsive.desktop ? colors.gray.background : colors.white,
              }}
            >
              <DocumentTemplatePreview
                documentTemplateUuid={previewedDocumentTemplate?.uuid}
                jobUuid={_.get(form.values, 'generateCustomDocumentsForJobForm.jobUuid')}
                style={responsive.desktop ? {} : {padding: 16, backgroundColor: colors.white}}
                isContentOnly={!responsive.desktop}
              />
            </ScrollView>
          </DrawerWithAction.PopoutPanel>
        ),
      }}
    >
      <Loading loading={loading || !project} as={PageLoader}>
        {() => (
          <DocumentTemplateViewerContent
            project={project}
            documentTemplates={project.organization.documentTemplatesByCategory}
            setPreviewedDocumentTemplate={setPreviewedDocumentTemplate}
            previewedDocumentTemplate={previewedDocumentTemplate}
            form={form}
          />
        )}
      </Loading>
    </DrawerWithAction>
  );
};

const DocumentTemplateViewer = ({
  jobId,
  jobUuid,
  project,
  documentTemplateViewer,
  refetch,
}: {
  jobId?: string;
  jobUuid?: string;
  project: ProjectModel;
  documentTemplateViewer: {isOpen: boolean; handleClose: () => void};
  refetch: () => void;
}) => {
  const {data, loading} = useQuery(DocumentTemplateViewer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: project.uuid,
    },
    skip: !documentTemplateViewer.isOpen,
  });
  const [previewedDocumentTemplate, setPreviewedDocumentTemplate] = useState<
    DocumentTemplateModel | undefined
  >();

  const generateCustomDocumentsForJobForm = GenerateCustomDocumentsForJobForm.new({
    jobId,
    jobUuid,
    organizationId: project.organizationId,
  });
  const {form, submitting, handleSubmit} = useGenerateDocumentsForJobMutation({
    generateCustomDocumentsForJobForm,
    onSuccess: () => {
      refetch();
      documentTemplateViewer.handleClose();
      generateCustomDocumentsSuccessToast.handleToast();
    },
    onError: (errors: MutationError[]) => {
      console.log({errors});
    },
  });
  const documentTemplateIdentifierCount = _.size(
    _.get(form.values, 'generateCustomDocumentsForJobForm.documentTemplateIdentifiers'),
  );
  const generateCustomDocumentsSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: `${documentTemplateIdentifierCount} ${pluralize(
      'document',
      documentTemplateIdentifierCount,
    )} generated`,
  });

  return (
    <DocumentTemplateDrawer
      loading={loading}
      project={data?.project}
      previewedDocumentTemplate={previewedDocumentTemplate}
      setPreviewedDocumentTemplate={setPreviewedDocumentTemplate}
      isOpen={documentTemplateViewer.isOpen}
      handleClose={() => {
        documentTemplateViewer.handleClose();
        setPreviewedDocumentTemplate(undefined);
        form.resetForm();
      }}
      documentTemplateIdentifierCount={documentTemplateIdentifierCount}
      form={form}
      submitting={submitting}
      handleSubmit={handleSubmit}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentTemplateViewer.query = gql`
  query DocumentTemplateViewer($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      activeJobs {
        id
        uuid
        identifier
        jobType {
          id
          name
        }
      }
      organization {
        id
        documentTemplatesByCategory(categories: ["JOB"]){
          id
          name
          uuid
          identifier
          activeOrMostRecentDocumentTemplateVersion {
            id
          }
        }
      }
    }
  }
`;

DocumentTemplateViewer.fragment = gql`
  fragment DocumentTemplateViewer on Project {
    id
    uuid
    organizationId
  }
`;

export default DocumentTemplateViewer;
