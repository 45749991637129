// Libraries
import React from 'react';

// Supermove
import {Link, Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {ResponsiveType, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {ProjectModel, UserModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Phone} from '@supermove/utils';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import Table from '@shared/design/components/Table';
import Tabs from '@shared/design/components/Tabs';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';

const HeaderContainer = Styled.View<{
  isResponsive?: boolean;
  responsive: ResponsiveType;
}>`
  padding-horizontal: ${({isResponsive, responsive}) => (isResponsive && !responsive.desktop ? 16 : 0)}px;
`;

const HeaderDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
  max-width: 700px;
`;

const TabsContainer = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  width: 100%;
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const OrganizationSettingsCompanyLeadProvidersLeadPage = () => {
  const {navigator, params} = useNavigationDOM();
  const responsive = useResponsive();
  const {loading, data} = useQuery(OrganizationSettingsCompanyLeadProvidersLeadPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inboundWebhookEndpointUuid: params.uuid,
    },
  });

  const Header = () => {
    return (
      <HeaderContainer isResponsive responsive={responsive}>
        <Space height={32} />
        <Breadcrumbs
          breadcrumbs={[
            {
              name: 'Lead Providers',
              onPress: () => navigator.push('/settings/company/lead-providers'),
            },
            {
              name: data?.leadProviderEndpoint.inboundWebhookEndpoint.name,
            },
          ]}
          mobileOptions={{showPreviousScreenOnly: true}}
        />
        <Space height={12} />
        <HeaderDescription responsive={responsive}>
          {'View and access leads from this provider.'}
        </HeaderDescription>
        <Space height={12} />
        <TabsContainer>
          <Space height={8} />
          <Tabs
            tabs={[
              {
                label: 'Leads',
              },
              {
                label: 'Logs',
              },
            ]}
            handlePressTab={() => console.log('TODO')}
            activeTabIndex={0}
            isSpacedTabs
          />
        </TabsContainer>
      </HeaderContainer>
    );
  };

  return (
    <CompanySettingsPage HeaderComponent={Header} title={''} description={''}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => <Content viewer={data.viewer} projects={data.leadProviderEndpoint.projects} />}
      </Loading>
    </CompanySettingsPage>
  );
};

const Content = ({viewer, projects}: {viewer: UserModel; projects: ProjectModel[]}) => {
  const {viewingOrganization, role} = viewer;

  const columnDefinitions = [
    {
      headerLabel: 'Project',
      flex: 3,
      cellComponent: (project: ProjectModel) => (
        <Link to={`/projects/${project.uuid}`}>
          <LinkText>{project.identifier}</LinkText>
        </Link>
      ),
    },
    {
      headerLabel: 'Client',
      flex: 4,
      cellText: (project: ProjectModel) => project.client.name,
    },
    {
      headerLabel: 'Phone Number',
      flex: 2,
      cellText: (project: ProjectModel) => Phone.display(project.client.primaryContact.phoneNumber),
    },
    {
      headerLabel: 'Email',
      flex: 4,
      cellText: (project: ProjectModel) => project.client.primaryContact.email,
    },
    {
      headerLabel: 'Created At',
      flex: 2,
      cellText: (project: ProjectModel) =>
        Datetime.toDisplayTime(
          Datetime.fromDatetime(project.createdAt),
          Datetime.DISPLAY_DATETIME,
          viewingOrganization.timezone,
        ),
    },
  ];

  return (
    <Table
      items={projects}
      itemKey={'id'}
      columnDefinitions={columnDefinitions}
      hasBorder
      emptyStateText={'No lead providers added yet.'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyLeadProvidersLeadPage.query = gql`
  query OrganizationSettingsCompanyLeadProvidersLeadPage($inboundWebhookEndpointUuid: String!){
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        timezone
      }
    }
    leadProviderEndpoint: leadProviderEndpoint(inboundWebhookEndpointUuid: $inboundWebhookEndpointUuid) {
      id
      inboundWebhookEndpoint {
        id
        name
      }
      projects {
        id
        uuid
        createdAt
        identifier
        client {
          id
          name
          primaryContact {
            id
            phoneNumber
            email
          }
        } 
      }
    }
  }
`;

export default OrganizationSettingsCompanyLeadProvidersLeadPage;
