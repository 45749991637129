// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, FileDragInput, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  Form,
  useForm,
  useMountEffect,
  useState,
  useDrawer,
  useInternet,
  useToast,
} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {Currency, uuid} from '@supermove/utils';

// App
import Callout from '@shared/design/components/Callout';
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import SearchBar from '@shared/design/components/SearchBar';
import Toast from '@shared/design/components/Toast';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';
import InventoryRoomsForm, {
  InventoryRoomsFormType,
} from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import ItemAttachmentForm, {
  ItemAttachmentFormType,
} from '@shared/modules/Inventory/forms/ItemAttachmentForm';
import {
  ItemFormV2Type,
  ItemFormV2PartialToFormType,
} from '@shared/modules/Inventory/forms/ItemFormV2';
import EditInventoryItemPhotoDetailsPopoutPanel from 'modules/Inventory/Edit/components/EditInventoryItemPhotoDetailsPopoutPanel';
import EditInventoryItemPhotosListView from 'modules/Inventory/Edit/components/EditInventoryItemPhotosListView';

const DrawerContentContainer = Styled.View`
  flex: 1;
`;

const SearchContainer = Styled.View`
`;

const CalloutsContainer = Styled.View`
`;

const FilesContainer = Styled.View`
  flex: 1;
`;

// TODO(Hammad) Implement types
export type FormFileType = any;
export type ItemFilesFormType = Form<{files: FormFileType[]; errors: {}}>;

const getInitialFilesFormValues = ({
  existingItemAttachmentForms,
}: {
  existingItemAttachmentForms: ItemAttachmentFormType[];
}) => {
  if (existingItemAttachmentForms) {
    const files = existingItemAttachmentForms.map((itemAttachmentForm: ItemAttachmentFormType) => ({
      id: itemAttachmentForm.attachmentId,
      fileId: itemAttachmentForm.fileId,
      uuid: itemAttachmentForm.uuid,
      filename: itemAttachmentForm.filename,
      description: itemAttachmentForm.description,
      downloadUrl: itemAttachmentForm.downloadUrl,
      uploadedBy: itemAttachmentForm.uploadedBy,
      uploadedAt: itemAttachmentForm.uploadedAt,
      isDeleted: itemAttachmentForm.isDeleted,
    }));

    return {
      files,
      errors: {},
    };
  }

  return {
    files: [],
    errors: {},
  };
};

const handleAddFiles = ({
  itemFilesForm,
  rawFiles,
}: {
  itemFilesForm: ItemFilesFormType;
  rawFiles: File[];
}) => {
  const currentFiles = _.get(itemFilesForm.values, 'files');
  const newFilesFormatted = rawFiles.map((rawFile: File) => ({rawFile, uuid: uuid()}));

  itemFilesForm.setFieldValue('files', [...currentFiles, ...newFilesFormatted]);
};

const handleUpdateFormFile = ({
  itemFilesForm,
  newFormFile,
  inventoryRoomsForm,
  roomItemsFormIndex,
  form,
}: {
  itemFilesForm: ItemFilesFormType;
  newFormFile: FormFileType;
  inventoryRoomsForm: Form<InventoryRoomsFormType>;
  roomItemsFormIndex: number;
  form: Form<ItemFormV2PartialToFormType>;
}) => {
  // Update the file at the index given the uuid (which will not change)
  const currentFormFiles = _.get(itemFilesForm.values, 'files');
  const indexToUpdate = _.findIndex(currentFormFiles, {uuid: newFormFile.uuid});
  const updatedFormFiles = currentFormFiles.map((formFile: FormFileType, index: number) =>
    index === indexToUpdate ? newFormFile : formFile,
  );

  itemFilesForm.setFieldValue('files', updatedFormFiles);
  updateInventoryRoomsForm({
    formFiles: updatedFormFiles,
    inventoryRoomsForm,
    roomItemsFormIndex,
    form,
  });
};

// TODO(Hammad) Implement type
type UploadedFileType = any;

const PhotoUpload = ({
  file,
  organizationId,
  viewer,
  onUploadComplete,
}: {
  file: File;
  organizationId: string;
  viewer: UserModel;
  onUploadComplete: (file: UploadedFileType) => void;
}) => {
  const {form: uploadFileForm, handleSubmit} = useUploadFileForm({
    uploadFileForm: UploadFileForm.new({
      organizationId,
      creatorId: viewer.id,
    }),
    onSuccess: ({file}) => {
      onUploadComplete(file);
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  // Kick off the upload when this component mounts
  useMountEffect(() => {
    // Inject these values into the form given the selected file
    uploadFileForm.setFieldValue(`uploadFileForm.requestUploadFileForm.mimetype`, file.type);
    uploadFileForm.setFieldValue(
      `uploadFileForm.requestUploadFileForm.filename`,
      UploadFileForm.formatName(file.name),
    );
    uploadFileForm.setFieldValue(`uploadFileForm.file`, file);
    setImmediate(handleSubmit);
  });

  return null;
};

const updateInventoryRoomsForm = ({
  formFiles,
  inventoryRoomsForm,
  roomItemsFormIndex,
  form,
}: {
  formFiles: FormFileType[];
  inventoryRoomsForm: Form<InventoryRoomsFormType>;
  roomItemsFormIndex: number;
  form: Form<ItemFormV2PartialToFormType>;
}) => {
  const itemAttachmentForms = formFiles
    // Only uploaded files have a formFile.fileId value
    .filter((formFile: FormFileType) => formFile.fileId)
    .map((formFile: FormFileType) => ItemAttachmentForm.newWithAttachmentId(formFile));
  const updatedValues = {
    ...form.values,
    isDirty: true,
    price: Currency.toMutation(form.values.price),
    itemAttachmentForms,
  };
  const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${form.values.index}`;

  inventoryRoomsForm.setFieldValue(`${field}`, updatedValues);
  InventoryRoomsForm.setDirtyForms({
    inventoryRoomsForm,
    roomItemsFormIndex,
    itemIndex: form.values.index,
  });
};

const EditInventoryItemPhotosDrawer = ({
  isOpen,
  handleClose,
  inventoryRoomsForm,
  roomItemsFormIndex,
  itemForm,
  organizationId,
  viewer,
  setFileCount,
}: {
  isOpen: boolean;
  handleClose: () => void;
  inventoryRoomsForm: Form<InventoryRoomsFormType>;
  roomItemsFormIndex: number;
  itemForm: ItemFormV2Type;
  organizationId: string;
  viewer: UserModel;
  setFileCount: (fileCount: number) => void;
}) => {
  /** *************************************************
   * Hooks
   ************************************************** */
  const {isConnected} = useInternet();
  const photoDeletedToast = useToast({
    ToastComponent: Toast,
    message: 'Photo deleted.',
  });

  /** *************************************************
   * Forms
   ************************************************** */
  // We clone the form and make edits there so that all the changes are made at once
  const clonedItemForm = _.cloneDeep(itemForm);
  // ItemFormV2 does not toForm currency values, so we need to do that here
  const form = useForm({
    initialValues: {...clonedItemForm, price: Currency.toForm(clonedItemForm.price)},
  });

  // itemFilesForm manages the local state for the file upload workflow.
  const itemFilesForm = useForm({
    initialValues: getInitialFilesFormValues({
      existingItemAttachmentForms: itemForm.itemAttachmentForms,
    }),
  });

  const handleUpdateInventoryRoomsForm = (updatedFormFiles: FormFileType[]) => {
    updateInventoryRoomsForm({
      formFiles: updatedFormFiles,
      inventoryRoomsForm,
      roomItemsFormIndex,
      form,
    });
  };

  /** *************************************************
   * State
   ************************************************** */
  const [search, setSearch] = useState('');

  const activeFormFiles = itemFilesForm.values.files.filter(
    (formFile: FormFileType) => !formFile.isDeleted,
  );
  const filteredFormFiles = activeFormFiles.filter((formFile: FormFileType) => {
    if (!search || !formFile.fileId) {
      return true;
    }
    return formFile.filename.toLowerCase().includes(search.toLowerCase());
  });
  // We only want to count files that have been uploaded.
  setFileCount(activeFormFiles.filter((formFile: FormFileType) => formFile.fileId).length);

  /** *************************************************
   * Drawer Handlers
   ************************************************** */
  const viewPhotoDetailsPopoutDrawer = useDrawer({name: 'View Photo Details Popout Drawer'});
  const [popoutFormFileIndex, setPopoutFormFileIndex] = useState(0);

  const handleOpenPopoutDrawer = (index: number) => {
    viewPhotoDetailsPopoutDrawer.handleOpen();
    setPopoutFormFileIndex(index);
  };

  const handleClosePopoutDrawer = () => {
    viewPhotoDetailsPopoutDrawer.handleClose();
    setPopoutFormFileIndex(0);
  };

  return (
    <DrawerWithClose
      isOpen={isOpen}
      handleClose={handleClose}
      width={DrawerWithClose.WIDTH.MEDIUM}
      headerText={`${clonedItemForm.name} Photos`}
      bodyProps={{
        bodyScrollStyle: {flex: 1},
      }}
      popoutPanel={{
        isOpen: viewPhotoDetailsPopoutDrawer.isOpen,
        width: DrawerWithClose.WIDTH.MEDIUM,
        component: (
          <EditInventoryItemPhotoDetailsPopoutPanel
            filteredFormFiles={filteredFormFiles}
            popoutFormFileIndex={popoutFormFileIndex}
            setPopoutFormFileIndex={setPopoutFormFileIndex}
            handleClose={viewPhotoDetailsPopoutDrawer.handleClose}
            itemFilesForm={itemFilesForm}
            handleClosePopoutDrawer={handleClosePopoutDrawer}
            handleUpdateInventoryRoomsForm={handleUpdateInventoryRoomsForm}
            photoDeletedToast={photoDeletedToast}
          />
        ),
      }}
    >
      <DrawerContentContainer>
        <SearchContainer>
          <SearchBar
            onChangeText={(searchQuery: string) => {
              setSearch(searchQuery);
              handleClosePopoutDrawer();
            }}
            placeholder={'Search'}
            containerStyle={{flex: 1}}
            style={{minWidth: '100px', width: '100%'}}
            defaultValue={''}
            isResponsive
          />
        </SearchContainer>
        <CalloutsContainer>
          {!isConnected && (
            <React.Fragment>
              <Space height={16} />
              <Callout
                icon={Icon.ExclamationTriangle}
                text={
                  "You're currently offline. Uploading photos will be available once you're online."
                }
              />
            </React.Fragment>
          )}
          {_.some(itemFilesForm.values.errors) && (
            <React.Fragment>
              <Space height={16} />
              <WarningCallout text={`Some photos failed to upload and weren't imported.`} />
            </React.Fragment>
          )}
        </CalloutsContainer>
        <FilesContainer>
          <Space height={16} />
          <FileDragInput
            style={{flex: 1}}
            accept={'image/*'}
            disabled={!isConnected}
            disableDrag={!isConnected}
            disableClick={!isConnected || _.some(filteredFormFiles)}
            onFilesChange={(rawFiles) => {
              handleClosePopoutDrawer();
              handleAddFiles({itemFilesForm, rawFiles});
            }}
          >
            {({isDragActive}) => {
              return (
                <EditInventoryItemPhotosListView
                  isDragActive={isDragActive}
                  formFiles={filteredFormFiles}
                  itemFilesForm={itemFilesForm}
                  handleOpenPopoutDrawer={handleOpenPopoutDrawer}
                  handleClosePopoutDrawer={handleClosePopoutDrawer}
                  handleUpdateInventoryRoomsForm={handleUpdateInventoryRoomsForm}
                  handleAddFiles={handleAddFiles}
                  photoDeletedToast={photoDeletedToast}
                />
              );
            }}
          </FileDragInput>
          {/* The PhotoUpload component handles files that have been added but not yet uploaded. */}
          {filteredFormFiles.map((formFile: FormFileType) => {
            if (formFile.fileId) {
              return null;
            }
            return (
              <PhotoUpload
                key={formFile.uuid}
                file={formFile.rawFile}
                organizationId={organizationId}
                viewer={viewer}
                onUploadComplete={(uploadedFile: UploadedFileType) => {
                  const newFormFile = {
                    fileId: uploadedFile.id,
                    uuid: formFile.uuid,
                    filename: uploadedFile.filename,
                    downloadUrl: uploadedFile.downloadUrl,
                    uploadedBy: viewer.fullName,
                    uploadedAt: new Date().toISOString(),
                    description: '',
                    isDeleted: false,
                    rawFile: formFile.rawFile,
                  };
                  handleUpdateFormFile({
                    itemFilesForm,
                    newFormFile,
                    inventoryRoomsForm,
                    roomItemsFormIndex,
                    form,
                  });
                }}
              />
            );
          })}
        </FilesContainer>
      </DrawerContentContainer>
    </DrawerWithClose>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditInventoryItemPhotosDrawer.fragment = gql`
  fragment EditInventoryItemPhotosDrawer on User {
    id
    fullName
  }
`;

export default EditInventoryItemPhotosDrawer;
