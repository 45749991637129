// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import UserRole from '@shared/modules/User/enums/UserRole';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CompanyAccountPanel from 'modules/Organization/Settings/Communication/components/CompanyAccountPanel';
import EmailReplyToPanel from 'modules/Organization/Settings/Communication/components/EmailReplyToPanel';
import EmailSenderKindPanel from 'modules/Organization/Settings/Communication/components/EmailSenderKindPanel';
import EmailSystemNotificationsPanel from 'modules/Organization/Settings/Communication/components/EmailSystemNotificationsPanel';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const SectionTitle = Styled.Text`
  ${Typography.Responsive.Heading1}
`;

const Row = Styled.View`
  flex-direction: row;
`;

const OrganizationSettingsCommunicationGeneralPage = () => {
  const responsive = useResponsive();
  const {data, loading, refetch} = useQuery(OrganizationSettingsCommunicationGeneralPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SettingsPage.PageContent
      isResponsive
      title={'General'}
      // TODO: Update description when call forwarding is implemented
      // "Configure system notifications, the reply-to email address, and call forwarding."
      description={'Configure system notifications and manage email sender account.'}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          const hasEditPermissions = _.includes(UserRole.ADMIN_ROLES_PLUS_SUPER, data.viewer.role);
          const organization = data.viewer.viewingOrganization;
          return (
            <React.Fragment>
              <Row style={{paddingHorizontal: responsive.desktop ? 0 : 20}}>
                <SectionTitle responsive={responsive}>Email</SectionTitle>
              </Row>
              <Space height={24} />
              <EmailSystemNotificationsPanel
                key={organization.settings.notificationEmails.length}
                index={0}
                organization={organization}
                refetch={refetch}
                hasEditPermissions={hasEditPermissions}
              />
              <React.Fragment>
                <Space height={24} />
                <CompanyAccountPanel
                  index={1}
                  organization={organization}
                  refetch={refetch}
                  hasEditPermissions={hasEditPermissions}
                />
                <Space height={24} />
                {organization.activeAccountGrant ? (
                  <EmailSenderKindPanel
                    index={2}
                    organization={organization}
                    refetch={refetch}
                    hasEditPermissions={hasEditPermissions}
                  />
                ) : (
                  <EmailReplyToPanel
                    key={organization.settings.replyToKind}
                    index={3}
                    organization={organization}
                    refetch={refetch}
                    hasEditPermissions={hasEditPermissions}
                  />
                )}
              </React.Fragment>
            </React.Fragment>
          );
        }}
      </Loading>
    </SettingsPage.PageContent>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCommunicationGeneralPage.query = gql`
  ${EmailReplyToPanel.fragment}
  ${EmailSystemNotificationsPanel.fragment}
  ${EmailSenderKindPanel.fragment}
  ${CompanyAccountPanel.fragment}
  query OrganizationSettingsCommunicationGeneralPage {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        activeAccountGrant {
          id
        }
        settings {
          id
          notificationEmails
          replyToKind
        }
        ...EmailReplyToPanel
        ...EmailSystemNotificationsPanel
        ...EmailSenderKindPanel
        ...CompanyAccountPanel
      }
    }
  }
`;

export default OrganizationSettingsCommunicationGeneralPage;
