// Libraries
import React, {useEffect, useState} from 'react';

// Supermove
import {PageLoader, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useUrlFilters} from '@supermove/hooks';
import {UserCalendarEventModel} from '@supermove/models';
import {Datetime} from '@supermove/utils';

// App
import UserCalendar from 'modules/User/Settings/Scheduling/ViewCalendar/components/UserCalendar';
import UserSettingsPageWrapper from 'modules/User/Settings/components/UserSettingsPageWrapper';

// Constants
export enum VIEW_TYPE {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  WORK_WEEK = 'WORK_WEEK',
  DAY = 'DAY',
  AGENDA = 'AGENDA',
}

const Content = Styled.View`
`;

const ViewUserCalendarContent = ({
  events,
  startTime,
  endTime,
  viewType,
  onViewTypeChange,
}: {
  events: UserCalendarEventModel[];
  startTime: number;
  endTime: number;
  viewType: VIEW_TYPE;
  onViewTypeChange: (viewType: VIEW_TYPE) => void;
}) => {
  return (
    <Content>
      <UserCalendar events={events} startTime={startTime} endTime={endTime} />
    </Content>
  );
};

const getTimeRangeForViewType = (
  viewType: VIEW_TYPE,
  baseTime: number = Datetime.now.unix(),
): {startTime: number; endTime: number} => {
  const baseDate = Datetime.fromUnix(baseTime);

  switch (viewType) {
    case VIEW_TYPE.MONTH:
      return {
        startTime: baseDate.startOf('month').unix(),
        endTime: baseDate.endOf('month').unix(),
      };
    case VIEW_TYPE.WEEK:
      return {
        startTime: baseDate.startOf('week').unix(),
        endTime: baseDate.endOf('week').unix(),
      };
    case VIEW_TYPE.WORK_WEEK:
      return {
        startTime: baseDate.startOf('week').add(1, 'day').unix(), // Monday
        endTime: baseDate.startOf('week').add(5, 'day').endOf('day').unix(), // Friday
      };
    case VIEW_TYPE.DAY:
      return {
        startTime: baseDate.startOf('day').unix(),
        endTime: baseDate.endOf('day').unix(),
      };
    case VIEW_TYPE.AGENDA:
    default:
      return {
        startTime: baseDate.unix(),
        endTime: baseDate.add(30, 'days').unix(),
      };
  }
};

type ViewUserCalendarUrlFiltersType = {
  startTime?: number;
  endTime?: number;
  viewType?: VIEW_TYPE;
};

const ViewUserCalendarPage = () => {
  const {params} = useNavigationDOM();
  const urlFilters = useUrlFilters<ViewUserCalendarUrlFiltersType>({
    getRoute: () => `/account/scheduling/calendars/${params.calendarId}/view`,
    filterKeys: ['startTime', 'endTime', 'viewType'],
  });

  const viewTypeParam = urlFilters.get('viewType') as VIEW_TYPE | undefined;
  const [viewType, setViewType] = useState<VIEW_TYPE>(viewTypeParam || VIEW_TYPE.MONTH);

  const startTimeParam = urlFilters.get('startTime');
  const endTimeParam = urlFilters.get('endTime');

  // Get default time range based on view type if not specified in URL
  const timeRange = getTimeRangeForViewType(viewType);
  const startTime = startTimeParam || timeRange.startTime;
  const endTime = endTimeParam || timeRange.endTime;

  const [isInitialized, setIsInitialized] = useState(false);

  // Set default URL parameters on mount if they're not already set
  useEffect(() => {
    if (!isInitialized) {
      const paramsToUpdate: ViewUserCalendarUrlFiltersType = {};

      if (!viewTypeParam) {
        paramsToUpdate.viewType = viewType;
      }

      if (!startTimeParam) {
        paramsToUpdate.startTime = timeRange.startTime;
      }

      if (!endTimeParam) {
        paramsToUpdate.endTime = timeRange.endTime;
      }

      // Only update URL if any parameters need to be set
      if (Object.keys(paramsToUpdate).length > 0) {
        urlFilters.handleUpdate(paramsToUpdate);
      }

      setIsInitialized(true);
    }
  }, []);

  const handleViewTypeChange = (newViewType: VIEW_TYPE) => {
    setViewType(newViewType);
    // Update URL filters with new view type and time range
    const newTimeRange = getTimeRangeForViewType(newViewType);
    urlFilters.handleUpdate({
      viewType: newViewType,
      startTime: newTimeRange.startTime,
      endTime: newTimeRange.endTime,
    });
  };

  const {loading, data, refetch} = useQuery(ViewUserCalendarPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      calendarId: params.calendarId,
      startTime,
      endTime,
    },
    skip: !isInitialized,
  });

  useEffect(() => {
    refetch();
  }, [startTime, endTime, viewType, isInitialized]);

  return (
    <UserSettingsPageWrapper selectedLabel={'Scheduling/Calendar'} pageTitle={'Calendar'}>
      <PageLoader loading={loading || !isInitialized} data={data}>
        {({loadedData}) => (
          <ViewUserCalendarContent
            events={loadedData.viewer.calendarEvents}
            startTime={startTime}
            endTime={endTime}
            viewType={viewType}
            onViewTypeChange={handleViewTypeChange}
          />
        )}
      </PageLoader>
    </UserSettingsPageWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewUserCalendarPage.query = gql`
  ${UserCalendar.fragment}
  
  query ViewUserCalendarPage($calendarId: String!, $startTime: Int!, $endTime: Int!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
      }
      calendar(calendarId: $calendarId) {
        id
        name
      }
      ...UserCalendar
    }
  }
`;

export default ViewUserCalendarPage;
