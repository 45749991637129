// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToast, MutationError} from '@supermove/hooks';
import {UserModel} from '@supermove/models';

// App
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import UserForm from '@shared/modules/User/forms/UserForm';
import useUpdateUserMutation from '@shared/modules/User/hooks/useUpdateUserMutation';

const UpdateOfficeUserStatusModal = ({
  isOpen,
  handleClose,
  officeUser,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  officeUser: UserModel;
  refetch: () => void;
}) => {
  const successToast = useToast({ToastComponent: SuccessToast, message: 'Changes saved.'});
  const userForm = UserForm.edit(officeUser);
  const {form, submitting, handleSubmit} = useUpdateUserMutation({
    userForm,
    onSuccess: () => {
      successToast.handleToast();
      refetch();
      handleClose();
    },
    onError: (errors: MutationError[]) => console.log({errors}),
  });
  const handleSubmitUpdateUserStatus = ({isActive}: {isActive: boolean}) => {
    form.setFieldValue('userForm.isActive', isActive);
    setImmediate(handleSubmit);
  };

  return (
    <React.Fragment>
      {officeUser.isActive ? (
        <DeleteModal
          isOpen={isOpen}
          title={`Deactivate ${officeUser.fullName}?`}
          subtitle={'This team member will no longer have access to the platform.'}
          handleClose={handleClose}
          handleDelete={() => handleSubmitUpdateUserStatus({isActive: false})}
          isSubmitting={submitting}
          deleteButtonText={'Confirm'}
        />
      ) : (
        <ConfirmationModal
          isOpen={isOpen}
          title={`Reactivate ${officeUser.fullName}?`}
          subtitle={'This team member will gain access to the platform.'}
          icon={Icon.Check}
          handlePrimaryAction={() => handleSubmitUpdateUserStatus({isActive: true})}
          primaryActionText={'Confirm'}
          handleSecondaryAction={handleClose}
          secondaryActionText={'Cancel'}
        />
      )}
    </React.Fragment>
  );
};

UpdateOfficeUserStatusModal.fragment = gql`
  ${UserForm.edit.fragment}

  fragment UpdateOfficeUserStatusModal on User {
    id
    fullName
    isActive
    ...UserForm_edit
  }
`;

export default UpdateOfficeUserStatusModal;
